const formatDate = (dateAsIsoString) => {
  // TODO resolve timezone
  if (!dateAsIsoString) return

  return new Intl.DateTimeFormat('en-GB', {
    literal: '.',
    hour12: false,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  }).format(new Date(dateAsIsoString))
}

const addMinutes = (date, minutes) => {
  return new Date(date.getTime() + minutes * 60000)
}

const addSeconds = (date, seconds) => {
  return new Date(date.getTime() + seconds * 1000)
}

module.exports = {
  formatDate,
  addMinutes,
  addSeconds,
}
