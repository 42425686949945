/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import LockOutlined from "@ant-design/icons/lib/icons/LockOutlined";
import Button from "antd/lib/button";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Row from "antd/lib/row";
import auth from "../../services/auth";
import { Context } from "../../store";

import "./index.css";

function LoginPage() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);
  const [state, dispatch] = useContext(Context);

  useEffect(() => {
    const token = auth.getAuthCookies();
    if (token) {
      history.push("/main/users");
    }
  }, []);

  const onFinish = async (values) => {
    try {
      const { data: userInfo } = await auth.login(values);

      dispatch({ type: "SET_USER", payload: userInfo });

      history.push("/main/users");
    } catch (e) {
      if (e?.data?.code === "no-permissions" || "user-disabled") {
        toast(e.data.message, {
          type: "warning",
        });

        form.resetFields();

        return <Redirect to="/" />;
      } else {
        console.error(e);
      }
    }
  };

  const handlerFieldsChange = (changedFields, allFields) => {
    if (
      allFields.some(function (field) {
        return field.errors.length > 0 || field.touched === false;
      })
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "Is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  return (
    <Row align="middle" justify="center" style={{ height: "100vh" }}>
      <Form
        form={form}
        onFieldsChange={handlerFieldsChange}
        name="normal_login"
        className="login-form"
        validateMessages={validateMessages}
        initialValues={{
          remember: true,
        }}
        style={{ width: "494px" }}
        onFinish={onFinish}>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter a valid email address!",
              whitespace: true,
            },
            { type: "email" },
          ]}>
          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your Password!",
            },
            {
              type: "string",
              min: 8,
              message: "Password must be more than 8 symbols!",
            },
          ]}>
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            disabled={disabled}>
            Log in
          </Button>
        </Form.Item>
      </Form>
    </Row>
  );
}

export default LoginPage;
