import React from "react";
import PropTypes from "prop-types";
import Row from "antd/lib/grid/row";

function PagerPrevNext({ next }) {
  if (!next) {
    return <Row gutter={24}>{`<<`}</Row>;
  }
  return (
    <Row gutter={24}>
      {`<<`} - {`>>`}
    </Row>
  );
}

PagerPrevNext.propTypes = {
  next: PropTypes.string,
};

export default PagerPrevNext;
