// { name: 'Afghanistan', code: 'AF' },
const PT_COUNTRIES = [
  {
    type: 'countries',
    id: 'AF',
    attributes: { 'iso3166-1-alpha-2': 'AF', name: 'Afghanistan' },
    links: { self: '/v2/countries/AF' },
  },
  {
    type: 'countries',
    id: 'AX',
    attributes: { 'iso3166-1-alpha-2': 'AX', name: 'Åland Islands' },
    links: { self: '/v2/countries/AX' },
  },
  {
    type: 'countries',
    id: 'AL',
    attributes: { 'iso3166-1-alpha-2': 'AL', name: 'Albania' },
    links: { self: '/v2/countries/AL' },
  },
  {
    type: 'countries',
    id: 'DZ',
    attributes: { 'iso3166-1-alpha-2': 'DZ', name: 'Algeria' },
    links: { self: '/v2/countries/DZ' },
  },
  {
    type: 'countries',
    id: 'AS',
    attributes: { 'iso3166-1-alpha-2': 'AS', name: 'American Samoa' },
    links: { self: '/v2/countries/AS' },
  },
  {
    type: 'countries',
    id: 'AD',
    attributes: { 'iso3166-1-alpha-2': 'AD', name: 'Andorra' },
    links: { self: '/v2/countries/AD' },
  },
  {
    type: 'countries',
    id: 'AO',
    attributes: { 'iso3166-1-alpha-2': 'AO', name: 'Angola' },
    links: { self: '/v2/countries/AO' },
  },
  {
    type: 'countries',
    id: 'AI',
    attributes: { 'iso3166-1-alpha-2': 'AI', name: 'Anguilla' },
    links: { self: '/v2/countries/AI' },
  },
  {
    type: 'countries',
    id: 'AQ',
    attributes: { 'iso3166-1-alpha-2': 'AQ', name: 'Antarctica' },
    links: { self: '/v2/countries/AQ' },
  },
  {
    type: 'countries',
    id: 'AG',
    attributes: { 'iso3166-1-alpha-2': 'AG', name: 'Antigua and Barbuda' },
    links: { self: '/v2/countries/AG' },
  },
  {
    type: 'countries',
    id: 'AR',
    attributes: { 'iso3166-1-alpha-2': 'AR', name: 'Argentina' },
    links: { self: '/v2/countries/AR' },
  },
  {
    type: 'countries',
    id: 'AM',
    attributes: { 'iso3166-1-alpha-2': 'AM', name: 'Armenia' },
    links: { self: '/v2/countries/AM' },
  },
  {
    type: 'countries',
    id: 'AW',
    attributes: { 'iso3166-1-alpha-2': 'AW', name: 'Aruba' },
    links: { self: '/v2/countries/AW' },
  },
  {
    type: 'countries',
    id: 'AU',
    attributes: { 'iso3166-1-alpha-2': 'AU', name: 'Australia' },
    links: { self: '/v2/countries/AU' },
  },
  {
    type: 'countries',
    id: 'AT',
    attributes: { 'iso3166-1-alpha-2': 'AT', name: 'Austria' },
    links: { self: '/v2/countries/AT' },
  },
  {
    type: 'countries',
    id: 'AZ',
    attributes: { 'iso3166-1-alpha-2': 'AZ', name: 'Azerbaijan' },
    links: { self: '/v2/countries/AZ' },
  },
  {
    type: 'countries',
    id: 'BS',
    attributes: { 'iso3166-1-alpha-2': 'BS', name: 'Bahamas' },
    links: { self: '/v2/countries/BS' },
  },
  {
    type: 'countries',
    id: 'BH',
    attributes: { 'iso3166-1-alpha-2': 'BH', name: 'Bahrain' },
    links: { self: '/v2/countries/BH' },
  },
  {
    type: 'countries',
    id: 'BD',
    attributes: { 'iso3166-1-alpha-2': 'BD', name: 'Bangladesh' },
    links: { self: '/v2/countries/BD' },
  },
  {
    type: 'countries',
    id: 'BB',
    attributes: { 'iso3166-1-alpha-2': 'BB', name: 'Barbados' },
    links: { self: '/v2/countries/BB' },
  },
  {
    type: 'countries',
    id: 'BY',
    attributes: { 'iso3166-1-alpha-2': 'BY', name: 'Belarus' },
    links: { self: '/v2/countries/BY' },
  },
  {
    type: 'countries',
    id: 'BE',
    attributes: { 'iso3166-1-alpha-2': 'BE', name: 'Belgium' },
    links: { self: '/v2/countries/BE' },
  },
  {
    type: 'countries',
    id: 'BZ',
    attributes: { 'iso3166-1-alpha-2': 'BZ', name: 'Belize' },
    links: { self: '/v2/countries/BZ' },
  },
  {
    type: 'countries',
    id: 'BJ',
    attributes: { 'iso3166-1-alpha-2': 'BJ', name: 'Benin' },
    links: { self: '/v2/countries/BJ' },
  },
  {
    type: 'countries',
    id: 'BM',
    attributes: { 'iso3166-1-alpha-2': 'BM', name: 'Bermuda' },
    links: { self: '/v2/countries/BM' },
  },
  {
    type: 'countries',
    id: 'BT',
    attributes: { 'iso3166-1-alpha-2': 'BT', name: 'Bhutan' },
    links: { self: '/v2/countries/BT' },
  },
  {
    type: 'countries',
    id: 'BO',
    attributes: { 'iso3166-1-alpha-2': 'BO', name: 'Bolivia (Plurinational State of)' },
    links: { self: '/v2/countries/BO' },
  },
  {
    type: 'countries',
    id: 'BQ',
    attributes: { 'iso3166-1-alpha-2': 'BQ', name: 'Bonaire, Sint Eustatius and Saba' },
    links: { self: '/v2/countries/BQ' },
  },
  {
    type: 'countries',
    id: 'BA',
    attributes: { 'iso3166-1-alpha-2': 'BA', name: 'Bosnia and Herzegovina' },
    links: { self: '/v2/countries/BA' },
  },
  {
    type: 'countries',
    id: 'BW',
    attributes: { 'iso3166-1-alpha-2': 'BW', name: 'Botswana' },
    links: { self: '/v2/countries/BW' },
  },
  {
    type: 'countries',
    id: 'BV',
    attributes: { 'iso3166-1-alpha-2': 'BV', name: 'Bouvet Island' },
    links: { self: '/v2/countries/BV' },
  },
  {
    type: 'countries',
    id: 'BR',
    attributes: { 'iso3166-1-alpha-2': 'BR', name: 'Brazil' },
    links: { self: '/v2/countries/BR' },
  },
  {
    type: 'countries',
    id: 'IO',
    attributes: { 'iso3166-1-alpha-2': 'IO', name: 'British Indian Ocean Territory' },
    links: { self: '/v2/countries/IO' },
  },
  {
    type: 'countries',
    id: 'BN',
    attributes: { 'iso3166-1-alpha-2': 'BN', name: 'Brunei Darussalam' },
    links: { self: '/v2/countries/BN' },
  },
  {
    type: 'countries',
    id: 'BG',
    attributes: { 'iso3166-1-alpha-2': 'BG', name: 'Bulgaria' },
    links: { self: '/v2/countries/BG' },
  },
  {
    type: 'countries',
    id: 'BF',
    attributes: { 'iso3166-1-alpha-2': 'BF', name: 'Burkina Faso' },
    links: { self: '/v2/countries/BF' },
  },
  {
    type: 'countries',
    id: 'BI',
    attributes: { 'iso3166-1-alpha-2': 'BI', name: 'Burundi' },
    links: { self: '/v2/countries/BI' },
  },
  {
    type: 'countries',
    id: 'CV',
    attributes: { 'iso3166-1-alpha-2': 'CV', name: 'Cabo Verde' },
    links: { self: '/v2/countries/CV' },
  },
  {
    type: 'countries',
    id: 'KH',
    attributes: { 'iso3166-1-alpha-2': 'KH', name: 'Cambodia' },
    links: { self: '/v2/countries/KH' },
  },
  {
    type: 'countries',
    id: 'CM',
    attributes: { 'iso3166-1-alpha-2': 'CM', name: 'Cameroon' },
    links: { self: '/v2/countries/CM' },
  },
  {
    type: 'countries',
    id: 'CA',
    attributes: { 'iso3166-1-alpha-2': 'CA', name: 'Canada' },
    links: { self: '/v2/countries/CA' },
  },
  {
    type: 'countries',
    id: 'KY',
    attributes: { 'iso3166-1-alpha-2': 'KY', name: 'Cayman Islands' },
    links: { self: '/v2/countries/KY' },
  },
  {
    type: 'countries',
    id: 'CF',
    attributes: { 'iso3166-1-alpha-2': 'CF', name: 'Central African Republic' },
    links: { self: '/v2/countries/CF' },
  },
  {
    type: 'countries',
    id: 'TD',
    attributes: { 'iso3166-1-alpha-2': 'TD', name: 'Chad' },
    links: { self: '/v2/countries/TD' },
  },
  {
    type: 'countries',
    id: 'CL',
    attributes: { 'iso3166-1-alpha-2': 'CL', name: 'Chile' },
    links: { self: '/v2/countries/CL' },
  },
  {
    type: 'countries',
    id: 'CN',
    attributes: { 'iso3166-1-alpha-2': 'CN', name: 'China' },
    links: { self: '/v2/countries/CN' },
  },
  {
    type: 'countries',
    id: 'CX',
    attributes: { 'iso3166-1-alpha-2': 'CX', name: 'Christmas Island' },
    links: { self: '/v2/countries/CX' },
  },
  {
    type: 'countries',
    id: 'CC',
    attributes: { 'iso3166-1-alpha-2': 'CC', name: 'Cocos (Keeling) Islands' },
    links: { self: '/v2/countries/CC' },
  },
  {
    type: 'countries',
    id: 'CO',
    attributes: { 'iso3166-1-alpha-2': 'CO', name: 'Colombia' },
    links: { self: '/v2/countries/CO' },
  },
  {
    type: 'countries',
    id: 'KM',
    attributes: { 'iso3166-1-alpha-2': 'KM', name: 'Comoros' },
    links: { self: '/v2/countries/KM' },
  },
  {
    type: 'countries',
    id: 'CG',
    attributes: { 'iso3166-1-alpha-2': 'CG', name: 'Congo' },
    links: { self: '/v2/countries/CG' },
  },
  {
    type: 'countries',
    id: 'CD',
    attributes: { 'iso3166-1-alpha-2': 'CD', name: 'Congo, Democratic Republic of the' },
    links: { self: '/v2/countries/CD' },
  },
  {
    type: 'countries',
    id: 'CK',
    attributes: { 'iso3166-1-alpha-2': 'CK', name: 'Cook Islands' },
    links: { self: '/v2/countries/CK' },
  },
  {
    type: 'countries',
    id: 'CR',
    attributes: { 'iso3166-1-alpha-2': 'CR', name: 'Costa Rica' },
    links: { self: '/v2/countries/CR' },
  },
  {
    type: 'countries',
    id: 'CI',
    attributes: { 'iso3166-1-alpha-2': 'CI', name: "Côte d'Ivoire" },
    links: { self: '/v2/countries/CI' },
  },
  {
    type: 'countries',
    id: 'HR',
    attributes: { 'iso3166-1-alpha-2': 'HR', name: 'Croatia' },
    links: { self: '/v2/countries/HR' },
  },
  {
    type: 'countries',
    id: 'CW',
    attributes: { 'iso3166-1-alpha-2': 'CW', name: 'Curaçao' },
    links: { self: '/v2/countries/CW' },
  },
  {
    type: 'countries',
    id: 'CY',
    attributes: { 'iso3166-1-alpha-2': 'CY', name: 'Cyprus' },
    links: { self: '/v2/countries/CY' },
  },
  {
    type: 'countries',
    id: 'CZ',
    attributes: { 'iso3166-1-alpha-2': 'CZ', name: 'Czechia' },
    links: { self: '/v2/countries/CZ' },
  },
  {
    type: 'countries',
    id: 'DK',
    attributes: { 'iso3166-1-alpha-2': 'DK', name: 'Denmark' },
    links: { self: '/v2/countries/DK' },
  },
  {
    type: 'countries',
    id: 'DJ',
    attributes: { 'iso3166-1-alpha-2': 'DJ', name: 'Djibouti' },
    links: { self: '/v2/countries/DJ' },
  },
  {
    type: 'countries',
    id: 'DM',
    attributes: { 'iso3166-1-alpha-2': 'DM', name: 'Dominica' },
    links: { self: '/v2/countries/DM' },
  },
  {
    type: 'countries',
    id: 'DO',
    attributes: { 'iso3166-1-alpha-2': 'DO', name: 'Dominican Republic' },
    links: { self: '/v2/countries/DO' },
  },
  {
    type: 'countries',
    id: 'EC',
    attributes: { 'iso3166-1-alpha-2': 'EC', name: 'Ecuador' },
    links: { self: '/v2/countries/EC' },
  },
  {
    type: 'countries',
    id: 'EG',
    attributes: { 'iso3166-1-alpha-2': 'EG', name: 'Egypt' },
    links: { self: '/v2/countries/EG' },
  },
  {
    type: 'countries',
    id: 'SV',
    attributes: { 'iso3166-1-alpha-2': 'SV', name: 'El Salvador' },
    links: { self: '/v2/countries/SV' },
  },
  {
    type: 'countries',
    id: 'GQ',
    attributes: { 'iso3166-1-alpha-2': 'GQ', name: 'Equatorial Guinea' },
    links: { self: '/v2/countries/GQ' },
  },
  {
    type: 'countries',
    id: 'ER',
    attributes: { 'iso3166-1-alpha-2': 'ER', name: 'Eritrea' },
    links: { self: '/v2/countries/ER' },
  },
  {
    type: 'countries',
    id: 'EE',
    attributes: { 'iso3166-1-alpha-2': 'EE', name: 'Estonia' },
    links: { self: '/v2/countries/EE' },
  },
  {
    type: 'countries',
    id: 'SZ',
    attributes: { 'iso3166-1-alpha-2': 'SZ', name: 'Eswatini' },
    links: { self: '/v2/countries/SZ' },
  },
  {
    type: 'countries',
    id: 'ET',
    attributes: { 'iso3166-1-alpha-2': 'ET', name: 'Ethiopia' },
    links: { self: '/v2/countries/ET' },
  },
  {
    type: 'countries',
    id: 'FK',
    attributes: { 'iso3166-1-alpha-2': 'FK', name: 'Falkland Islands (Malvinas)' },
    links: { self: '/v2/countries/FK' },
  },
  {
    type: 'countries',
    id: 'FO',
    attributes: { 'iso3166-1-alpha-2': 'FO', name: 'Faroe Islands' },
    links: { self: '/v2/countries/FO' },
  },
  {
    type: 'countries',
    id: 'FJ',
    attributes: { 'iso3166-1-alpha-2': 'FJ', name: 'Fiji' },
    links: { self: '/v2/countries/FJ' },
  },
  {
    type: 'countries',
    id: 'FI',
    attributes: { 'iso3166-1-alpha-2': 'FI', name: 'Finland' },
    links: { self: '/v2/countries/FI' },
  },
  {
    type: 'countries',
    id: 'FR',
    attributes: { 'iso3166-1-alpha-2': 'FR', name: 'France' },
    links: { self: '/v2/countries/FR' },
  },
  {
    type: 'countries',
    id: 'GF',
    attributes: { 'iso3166-1-alpha-2': 'GF', name: 'French Guiana' },
    links: { self: '/v2/countries/GF' },
  },
  {
    type: 'countries',
    id: 'PF',
    attributes: { 'iso3166-1-alpha-2': 'PF', name: 'French Polynesia' },
    links: { self: '/v2/countries/PF' },
  },
  {
    type: 'countries',
    id: 'TF',
    attributes: { 'iso3166-1-alpha-2': 'TF', name: 'French Southern Territories' },
    links: { self: '/v2/countries/TF' },
  },
  {
    type: 'countries',
    id: 'GA',
    attributes: { 'iso3166-1-alpha-2': 'GA', name: 'Gabon' },
    links: { self: '/v2/countries/GA' },
  },
  {
    type: 'countries',
    id: 'GM',
    attributes: { 'iso3166-1-alpha-2': 'GM', name: 'Gambia' },
    links: { self: '/v2/countries/GM' },
  },
  {
    type: 'countries',
    id: 'GE',
    attributes: { 'iso3166-1-alpha-2': 'GE', name: 'Georgia' },
    links: { self: '/v2/countries/GE' },
  },
  {
    type: 'countries',
    id: 'DE',
    attributes: { 'iso3166-1-alpha-2': 'DE', name: 'Germany' },
    links: { self: '/v2/countries/DE' },
  },
  {
    type: 'countries',
    id: 'GH',
    attributes: { 'iso3166-1-alpha-2': 'GH', name: 'Ghana' },
    links: { self: '/v2/countries/GH' },
  },
  {
    type: 'countries',
    id: 'GI',
    attributes: { 'iso3166-1-alpha-2': 'GI', name: 'Gibraltar' },
    links: { self: '/v2/countries/GI' },
  },
  {
    type: 'countries',
    id: 'GR',
    attributes: { 'iso3166-1-alpha-2': 'GR', name: 'Greece' },
    links: { self: '/v2/countries/GR' },
  },
  {
    type: 'countries',
    id: 'GL',
    attributes: { 'iso3166-1-alpha-2': 'GL', name: 'Greenland' },
    links: { self: '/v2/countries/GL' },
  },
  {
    type: 'countries',
    id: 'GD',
    attributes: { 'iso3166-1-alpha-2': 'GD', name: 'Grenada' },
    links: { self: '/v2/countries/GD' },
  },
  {
    type: 'countries',
    id: 'GP',
    attributes: { 'iso3166-1-alpha-2': 'GP', name: 'Guadeloupe' },
    links: { self: '/v2/countries/GP' },
  },
  {
    type: 'countries',
    id: 'GU',
    attributes: { 'iso3166-1-alpha-2': 'GU', name: 'Guam' },
    links: { self: '/v2/countries/GU' },
  },
  {
    type: 'countries',
    id: 'GT',
    attributes: { 'iso3166-1-alpha-2': 'GT', name: 'Guatemala' },
    links: { self: '/v2/countries/GT' },
  },
  {
    type: 'countries',
    id: 'GG',
    attributes: { 'iso3166-1-alpha-2': 'GG', name: 'Guernsey' },
    links: { self: '/v2/countries/GG' },
  },
  {
    type: 'countries',
    id: 'GN',
    attributes: { 'iso3166-1-alpha-2': 'GN', name: 'Guinea' },
    links: { self: '/v2/countries/GN' },
  },
  {
    type: 'countries',
    id: 'GW',
    attributes: { 'iso3166-1-alpha-2': 'GW', name: 'Guinea-Bissau' },
    links: { self: '/v2/countries/GW' },
  },
  {
    type: 'countries',
    id: 'GY',
    attributes: { 'iso3166-1-alpha-2': 'GY', name: 'Guyana' },
    links: { self: '/v2/countries/GY' },
  },
  {
    type: 'countries',
    id: 'HT',
    attributes: { 'iso3166-1-alpha-2': 'HT', name: 'Haiti' },
    links: { self: '/v2/countries/HT' },
  },
  {
    type: 'countries',
    id: 'HM',
    attributes: { 'iso3166-1-alpha-2': 'HM', name: 'Heard Island and McDonald Islands' },
    links: { self: '/v2/countries/HM' },
  },
  {
    type: 'countries',
    id: 'VA',
    attributes: { 'iso3166-1-alpha-2': 'VA', name: 'Holy See' },
    links: { self: '/v2/countries/VA' },
  },
  {
    type: 'countries',
    id: 'HN',
    attributes: { 'iso3166-1-alpha-2': 'HN', name: 'Honduras' },
    links: { self: '/v2/countries/HN' },
  },
  {
    type: 'countries',
    id: 'HK',
    attributes: { 'iso3166-1-alpha-2': 'HK', name: 'Hong Kong' },
    links: { self: '/v2/countries/HK' },
  },
  {
    type: 'countries',
    id: 'HU',
    attributes: { 'iso3166-1-alpha-2': 'HU', name: 'Hungary' },
    links: { self: '/v2/countries/HU' },
  },
  {
    type: 'countries',
    id: 'IS',
    attributes: { 'iso3166-1-alpha-2': 'IS', name: 'Iceland' },
    links: { self: '/v2/countries/IS' },
  },
  {
    type: 'countries',
    id: 'IN',
    attributes: { 'iso3166-1-alpha-2': 'IN', name: 'India' },
    links: { self: '/v2/countries/IN' },
  },
  {
    type: 'countries',
    id: 'ID',
    attributes: { 'iso3166-1-alpha-2': 'ID', name: 'Indonesia' },
    links: { self: '/v2/countries/ID' },
  },
  {
    type: 'countries',
    id: 'IQ',
    attributes: { 'iso3166-1-alpha-2': 'IQ', name: 'Iraq' },
    links: { self: '/v2/countries/IQ' },
  },
  {
    type: 'countries',
    id: 'IE',
    attributes: { 'iso3166-1-alpha-2': 'IE', name: 'Ireland' },
    links: { self: '/v2/countries/IE' },
  },
  {
    type: 'countries',
    id: 'IM',
    attributes: { 'iso3166-1-alpha-2': 'IM', name: 'Isle of Man' },
    links: { self: '/v2/countries/IM' },
  },
  {
    type: 'countries',
    id: 'IL',
    attributes: { 'iso3166-1-alpha-2': 'IL', name: 'Israel' },
    links: { self: '/v2/countries/IL' },
  },
  {
    type: 'countries',
    id: 'IT',
    attributes: { 'iso3166-1-alpha-2': 'IT', name: 'Italy' },
    links: { self: '/v2/countries/IT' },
  },
  {
    type: 'countries',
    id: 'JM',
    attributes: { 'iso3166-1-alpha-2': 'JM', name: 'Jamaica' },
    links: { self: '/v2/countries/JM' },
  },
  {
    type: 'countries',
    id: 'JP',
    attributes: { 'iso3166-1-alpha-2': 'JP', name: 'Japan' },
    links: { self: '/v2/countries/JP' },
  },
  {
    type: 'countries',
    id: 'JE',
    attributes: { 'iso3166-1-alpha-2': 'JE', name: 'Jersey' },
    links: { self: '/v2/countries/JE' },
  },
  {
    type: 'countries',
    id: 'JO',
    attributes: { 'iso3166-1-alpha-2': 'JO', name: 'Jordan' },
    links: { self: '/v2/countries/JO' },
  },
  {
    type: 'countries',
    id: 'KZ',
    attributes: { 'iso3166-1-alpha-2': 'KZ', name: 'Kazakhstan' },
    links: { self: '/v2/countries/KZ' },
  },
  {
    type: 'countries',
    id: 'KE',
    attributes: { 'iso3166-1-alpha-2': 'KE', name: 'Kenya' },
    links: { self: '/v2/countries/KE' },
  },
  {
    type: 'countries',
    id: 'KI',
    attributes: { 'iso3166-1-alpha-2': 'KI', name: 'Kiribati' },
    links: { self: '/v2/countries/KI' },
  },
  {
    type: 'countries',
    id: 'KR',
    attributes: { 'iso3166-1-alpha-2': 'KR', name: 'Korea, Republic of' },
    links: { self: '/v2/countries/KR' },
  },
  {
    type: 'countries',
    id: 'XK',
    attributes: { 'iso3166-1-alpha-2': 'XK', name: 'Kosovo' },
    links: { self: '/v2/countries/XK' },
  },
  {
    type: 'countries',
    id: 'KW',
    attributes: { 'iso3166-1-alpha-2': 'KW', name: 'Kuwait' },
    links: { self: '/v2/countries/KW' },
  },
  {
    type: 'countries',
    id: 'KG',
    attributes: { 'iso3166-1-alpha-2': 'KG', name: 'Kyrgyzstan' },
    links: { self: '/v2/countries/KG' },
  },
  {
    type: 'countries',
    id: 'LA',
    attributes: { 'iso3166-1-alpha-2': 'LA', name: "Lao People's Democratic Republic" },
    links: { self: '/v2/countries/LA' },
  },
  {
    type: 'countries',
    id: 'LV',
    attributes: { 'iso3166-1-alpha-2': 'LV', name: 'Latvia' },
    links: { self: '/v2/countries/LV' },
  },
  {
    type: 'countries',
    id: 'LB',
    attributes: { 'iso3166-1-alpha-2': 'LB', name: 'Lebanon' },
    links: { self: '/v2/countries/LB' },
  },
  {
    type: 'countries',
    id: 'LS',
    attributes: { 'iso3166-1-alpha-2': 'LS', name: 'Lesotho' },
    links: { self: '/v2/countries/LS' },
  },
  {
    type: 'countries',
    id: 'LR',
    attributes: { 'iso3166-1-alpha-2': 'LR', name: 'Liberia' },
    links: { self: '/v2/countries/LR' },
  },
  {
    type: 'countries',
    id: 'LY',
    attributes: { 'iso3166-1-alpha-2': 'LY', name: 'Libya' },
    links: { self: '/v2/countries/LY' },
  },
  {
    type: 'countries',
    id: 'LI',
    attributes: { 'iso3166-1-alpha-2': 'LI', name: 'Liechtenstein' },
    links: { self: '/v2/countries/LI' },
  },
  {
    type: 'countries',
    id: 'LT',
    attributes: { 'iso3166-1-alpha-2': 'LT', name: 'Lithuania' },
    links: { self: '/v2/countries/LT' },
  },
  {
    type: 'countries',
    id: 'LU',
    attributes: { 'iso3166-1-alpha-2': 'LU', name: 'Luxembourg' },
    links: { self: '/v2/countries/LU' },
  },
  {
    type: 'countries',
    id: 'MO',
    attributes: { 'iso3166-1-alpha-2': 'MO', name: 'Macao' },
    links: { self: '/v2/countries/MO' },
  },
  {
    type: 'countries',
    id: 'MG',
    attributes: { 'iso3166-1-alpha-2': 'MG', name: 'Madagascar' },
    links: { self: '/v2/countries/MG' },
  },
  {
    type: 'countries',
    id: 'MW',
    attributes: { 'iso3166-1-alpha-2': 'MW', name: 'Malawi' },
    links: { self: '/v2/countries/MW' },
  },
  {
    type: 'countries',
    id: 'MY',
    attributes: { 'iso3166-1-alpha-2': 'MY', name: 'Malaysia' },
    links: { self: '/v2/countries/MY' },
  },
  {
    type: 'countries',
    id: 'MV',
    attributes: { 'iso3166-1-alpha-2': 'MV', name: 'Maldives' },
    links: { self: '/v2/countries/MV' },
  },
  {
    type: 'countries',
    id: 'ML',
    attributes: { 'iso3166-1-alpha-2': 'ML', name: 'Mali' },
    links: { self: '/v2/countries/ML' },
  },
  {
    type: 'countries',
    id: 'MT',
    attributes: { 'iso3166-1-alpha-2': 'MT', name: 'Malta' },
    links: { self: '/v2/countries/MT' },
  },
  {
    type: 'countries',
    id: 'MH',
    attributes: { 'iso3166-1-alpha-2': 'MH', name: 'Marshall Islands' },
    links: { self: '/v2/countries/MH' },
  },
  {
    type: 'countries',
    id: 'MQ',
    attributes: { 'iso3166-1-alpha-2': 'MQ', name: 'Martinique' },
    links: { self: '/v2/countries/MQ' },
  },
  {
    type: 'countries',
    id: 'MR',
    attributes: { 'iso3166-1-alpha-2': 'MR', name: 'Mauritania' },
    links: { self: '/v2/countries/MR' },
  },
  {
    type: 'countries',
    id: 'MU',
    attributes: { 'iso3166-1-alpha-2': 'MU', name: 'Mauritius' },
    links: { self: '/v2/countries/MU' },
  },
  {
    type: 'countries',
    id: 'YT',
    attributes: { 'iso3166-1-alpha-2': 'YT', name: 'Mayotte' },
    links: { self: '/v2/countries/YT' },
  },
  {
    type: 'countries',
    id: 'MX',
    attributes: { 'iso3166-1-alpha-2': 'MX', name: 'Mexico' },
    links: { self: '/v2/countries/MX' },
  },
  {
    type: 'countries',
    id: 'FM',
    attributes: { 'iso3166-1-alpha-2': 'FM', name: 'Micronesia (Federated States of)' },
    links: { self: '/v2/countries/FM' },
  },
  {
    type: 'countries',
    id: 'MD',
    attributes: { 'iso3166-1-alpha-2': 'MD', name: 'Moldova, Republic of' },
    links: { self: '/v2/countries/MD' },
  },
  {
    type: 'countries',
    id: 'MC',
    attributes: { 'iso3166-1-alpha-2': 'MC', name: 'Monaco' },
    links: { self: '/v2/countries/MC' },
  },
  {
    type: 'countries',
    id: 'MN',
    attributes: { 'iso3166-1-alpha-2': 'MN', name: 'Mongolia' },
    links: { self: '/v2/countries/MN' },
  },
  {
    type: 'countries',
    id: 'ME',
    attributes: { 'iso3166-1-alpha-2': 'ME', name: 'Montenegro' },
    links: { self: '/v2/countries/ME' },
  },
  {
    type: 'countries',
    id: 'MS',
    attributes: { 'iso3166-1-alpha-2': 'MS', name: 'Montserrat' },
    links: { self: '/v2/countries/MS' },
  },
  {
    type: 'countries',
    id: 'MA',
    attributes: { 'iso3166-1-alpha-2': 'MA', name: 'Morocco' },
    links: { self: '/v2/countries/MA' },
  },
  {
    type: 'countries',
    id: 'MZ',
    attributes: { 'iso3166-1-alpha-2': 'MZ', name: 'Mozambique' },
    links: { self: '/v2/countries/MZ' },
  },
  {
    type: 'countries',
    id: 'MM',
    attributes: { 'iso3166-1-alpha-2': 'MM', name: 'Myanmar' },
    links: { self: '/v2/countries/MM' },
  },
  {
    type: 'countries',
    id: 'NA',
    attributes: { 'iso3166-1-alpha-2': 'NA', name: 'Namibia' },
    links: { self: '/v2/countries/NA' },
  },
  {
    type: 'countries',
    id: 'NR',
    attributes: { 'iso3166-1-alpha-2': 'NR', name: 'Nauru' },
    links: { self: '/v2/countries/NR' },
  },
  {
    type: 'countries',
    id: 'NP',
    attributes: { 'iso3166-1-alpha-2': 'NP', name: 'Nepal' },
    links: { self: '/v2/countries/NP' },
  },
  {
    type: 'countries',
    id: 'NL',
    attributes: { 'iso3166-1-alpha-2': 'NL', name: 'Netherlands' },
    links: { self: '/v2/countries/NL' },
  },
  {
    type: 'countries',
    id: 'NC',
    attributes: { 'iso3166-1-alpha-2': 'NC', name: 'New Caledonia' },
    links: { self: '/v2/countries/NC' },
  },
  {
    type: 'countries',
    id: 'NZ',
    attributes: { 'iso3166-1-alpha-2': 'NZ', name: 'New Zealand' },
    links: { self: '/v2/countries/NZ' },
  },
  {
    type: 'countries',
    id: 'NI',
    attributes: { 'iso3166-1-alpha-2': 'NI', name: 'Nicaragua' },
    links: { self: '/v2/countries/NI' },
  },
  {
    type: 'countries',
    id: 'NE',
    attributes: { 'iso3166-1-alpha-2': 'NE', name: 'Niger' },
    links: { self: '/v2/countries/NE' },
  },
  {
    type: 'countries',
    id: 'NG',
    attributes: { 'iso3166-1-alpha-2': 'NG', name: 'Nigeria' },
    links: { self: '/v2/countries/NG' },
  },
  {
    type: 'countries',
    id: 'NU',
    attributes: { 'iso3166-1-alpha-2': 'NU', name: 'Niue' },
    links: { self: '/v2/countries/NU' },
  },
  {
    type: 'countries',
    id: 'NF',
    attributes: { 'iso3166-1-alpha-2': 'NF', name: 'Norfolk Island' },
    links: { self: '/v2/countries/NF' },
  },
  {
    type: 'countries',
    id: 'MP',
    attributes: { 'iso3166-1-alpha-2': 'MP', name: 'Northern Mariana Islands' },
    links: { self: '/v2/countries/MP' },
  },
  {
    type: 'countries',
    id: 'MK',
    attributes: { 'iso3166-1-alpha-2': 'MK', name: 'North Macedonia' },
    links: { self: '/v2/countries/MK' },
  },
  {
    type: 'countries',
    id: 'NO',
    attributes: { 'iso3166-1-alpha-2': 'NO', name: 'Norway' },
    links: { self: '/v2/countries/NO' },
  },
  {
    type: 'countries',
    id: 'OM',
    attributes: { 'iso3166-1-alpha-2': 'OM', name: 'Oman' },
    links: { self: '/v2/countries/OM' },
  },
  {
    type: 'countries',
    id: 'PK',
    attributes: { 'iso3166-1-alpha-2': 'PK', name: 'Pakistan' },
    links: { self: '/v2/countries/PK' },
  },
  {
    type: 'countries',
    id: 'PW',
    attributes: { 'iso3166-1-alpha-2': 'PW', name: 'Palau' },
    links: { self: '/v2/countries/PW' },
  },
  {
    type: 'countries',
    id: 'PS',
    attributes: { 'iso3166-1-alpha-2': 'PS', name: 'Palestine, State of' },
    links: { self: '/v2/countries/PS' },
  },
  {
    type: 'countries',
    id: 'PA',
    attributes: { 'iso3166-1-alpha-2': 'PA', name: 'Panama' },
    links: { self: '/v2/countries/PA' },
  },
  {
    type: 'countries',
    id: 'PG',
    attributes: { 'iso3166-1-alpha-2': 'PG', name: 'Papua New Guinea' },
    links: { self: '/v2/countries/PG' },
  },
  {
    type: 'countries',
    id: 'PY',
    attributes: { 'iso3166-1-alpha-2': 'PY', name: 'Paraguay' },
    links: { self: '/v2/countries/PY' },
  },
  {
    type: 'countries',
    id: 'PE',
    attributes: { 'iso3166-1-alpha-2': 'PE', name: 'Peru' },
    links: { self: '/v2/countries/PE' },
  },
  {
    type: 'countries',
    id: 'PH',
    attributes: { 'iso3166-1-alpha-2': 'PH', name: 'Philippines' },
    links: { self: '/v2/countries/PH' },
  },
  {
    type: 'countries',
    id: 'PN',
    attributes: { 'iso3166-1-alpha-2': 'PN', name: 'Pitcairn' },
    links: { self: '/v2/countries/PN' },
  },
  {
    type: 'countries',
    id: 'PL',
    attributes: { 'iso3166-1-alpha-2': 'PL', name: 'Poland' },
    links: { self: '/v2/countries/PL' },
  },
  {
    type: 'countries',
    id: 'PT',
    attributes: { 'iso3166-1-alpha-2': 'PT', name: 'Portugal' },
    links: { self: '/v2/countries/PT' },
  },
  {
    type: 'countries',
    id: 'PR',
    attributes: { 'iso3166-1-alpha-2': 'PR', name: 'Puerto Rico' },
    links: { self: '/v2/countries/PR' },
  },
  {
    type: 'countries',
    id: 'QA',
    attributes: { 'iso3166-1-alpha-2': 'QA', name: 'Qatar' },
    links: { self: '/v2/countries/QA' },
  },
  {
    type: 'countries',
    id: 'RE',
    attributes: { 'iso3166-1-alpha-2': 'RE', name: 'Réunion' },
    links: { self: '/v2/countries/RE' },
  },
  {
    type: 'countries',
    id: 'RO',
    attributes: { 'iso3166-1-alpha-2': 'RO', name: 'Romania' },
    links: { self: '/v2/countries/RO' },
  },
  {
    type: 'countries',
    id: 'RU',
    attributes: { 'iso3166-1-alpha-2': 'RU', name: 'Russian Federation' },
    links: { self: '/v2/countries/RU' },
  },
  {
    type: 'countries',
    id: 'RW',
    attributes: { 'iso3166-1-alpha-2': 'RW', name: 'Rwanda' },
    links: { self: '/v2/countries/RW' },
  },
  {
    type: 'countries',
    id: 'BL',
    attributes: { 'iso3166-1-alpha-2': 'BL', name: 'Saint Barthélemy' },
    links: { self: '/v2/countries/BL' },
  },
  {
    type: 'countries',
    id: 'SH',
    attributes: { 'iso3166-1-alpha-2': 'SH', name: 'Saint Helena, Ascension and Tristan da Cunha' },
    links: { self: '/v2/countries/SH' },
  },
  {
    type: 'countries',
    id: 'KN',
    attributes: { 'iso3166-1-alpha-2': 'KN', name: 'Saint Kitts and Nevis' },
    links: { self: '/v2/countries/KN' },
  },
  {
    type: 'countries',
    id: 'LC',
    attributes: { 'iso3166-1-alpha-2': 'LC', name: 'Saint Lucia' },
    links: { self: '/v2/countries/LC' },
  },
  {
    type: 'countries',
    id: 'MF',
    attributes: { 'iso3166-1-alpha-2': 'MF', name: 'Saint Martin (French part)' },
    links: { self: '/v2/countries/MF' },
  },
  {
    type: 'countries',
    id: 'PM',
    attributes: { 'iso3166-1-alpha-2': 'PM', name: 'Saint Pierre and Miquelon' },
    links: { self: '/v2/countries/PM' },
  },
  {
    type: 'countries',
    id: 'VC',
    attributes: { 'iso3166-1-alpha-2': 'VC', name: 'Saint Vincent and the Grenadines' },
    links: { self: '/v2/countries/VC' },
  },
  {
    type: 'countries',
    id: 'WS',
    attributes: { 'iso3166-1-alpha-2': 'WS', name: 'Samoa' },
    links: { self: '/v2/countries/WS' },
  },
  {
    type: 'countries',
    id: 'SM',
    attributes: { 'iso3166-1-alpha-2': 'SM', name: 'San Marino' },
    links: { self: '/v2/countries/SM' },
  },
  {
    type: 'countries',
    id: 'ST',
    attributes: { 'iso3166-1-alpha-2': 'ST', name: 'Sao Tome and Principe' },
    links: { self: '/v2/countries/ST' },
  },
  {
    type: 'countries',
    id: 'SA',
    attributes: { 'iso3166-1-alpha-2': 'SA', name: 'Saudi Arabia' },
    links: { self: '/v2/countries/SA' },
  },
  {
    type: 'countries',
    id: 'SN',
    attributes: { 'iso3166-1-alpha-2': 'SN', name: 'Senegal' },
    links: { self: '/v2/countries/SN' },
  },
  {
    type: 'countries',
    id: 'RS',
    attributes: { 'iso3166-1-alpha-2': 'RS', name: 'Serbia' },
    links: { self: '/v2/countries/RS' },
  },
  {
    type: 'countries',
    id: 'SC',
    attributes: { 'iso3166-1-alpha-2': 'SC', name: 'Seychelles' },
    links: { self: '/v2/countries/SC' },
  },
  {
    type: 'countries',
    id: 'SL',
    attributes: { 'iso3166-1-alpha-2': 'SL', name: 'Sierra Leone' },
    links: { self: '/v2/countries/SL' },
  },
  {
    type: 'countries',
    id: 'SG',
    attributes: { 'iso3166-1-alpha-2': 'SG', name: 'Singapore' },
    links: { self: '/v2/countries/SG' },
  },
  {
    type: 'countries',
    id: 'SX',
    attributes: { 'iso3166-1-alpha-2': 'SX', name: 'Sint Maarten (Dutch part)' },
    links: { self: '/v2/countries/SX' },
  },
  {
    type: 'countries',
    id: 'SK',
    attributes: { 'iso3166-1-alpha-2': 'SK', name: 'Slovakia' },
    links: { self: '/v2/countries/SK' },
  },
  {
    type: 'countries',
    id: 'SI',
    attributes: { 'iso3166-1-alpha-2': 'SI', name: 'Slovenia' },
    links: { self: '/v2/countries/SI' },
  },
  {
    type: 'countries',
    id: 'SB',
    attributes: { 'iso3166-1-alpha-2': 'SB', name: 'Solomon Islands' },
    links: { self: '/v2/countries/SB' },
  },
  {
    type: 'countries',
    id: 'SO',
    attributes: { 'iso3166-1-alpha-2': 'SO', name: 'Somalia' },
    links: { self: '/v2/countries/SO' },
  },
  {
    type: 'countries',
    id: 'ZA',
    attributes: { 'iso3166-1-alpha-2': 'ZA', name: 'South Africa' },
    links: { self: '/v2/countries/ZA' },
  },
  {
    type: 'countries',
    id: 'GS',
    attributes: { 'iso3166-1-alpha-2': 'GS', name: 'South Georgia and the South Sandwich Islands' },
    links: { self: '/v2/countries/GS' },
  },
  {
    type: 'countries',
    id: 'SS',
    attributes: { 'iso3166-1-alpha-2': 'SS', name: 'South Sudan' },
    links: { self: '/v2/countries/SS' },
  },
  {
    type: 'countries',
    id: 'ES',
    attributes: { 'iso3166-1-alpha-2': 'ES', name: 'Spain' },
    links: { self: '/v2/countries/ES' },
  },
  {
    type: 'countries',
    id: 'LK',
    attributes: { 'iso3166-1-alpha-2': 'LK', name: 'Sri Lanka' },
    links: { self: '/v2/countries/LK' },
  },
  {
    type: 'countries',
    id: 'SD',
    attributes: { 'iso3166-1-alpha-2': 'SD', name: 'Sudan' },
    links: { self: '/v2/countries/SD' },
  },
  {
    type: 'countries',
    id: 'SR',
    attributes: { 'iso3166-1-alpha-2': 'SR', name: 'Suriname' },
    links: { self: '/v2/countries/SR' },
  },
  {
    type: 'countries',
    id: 'SJ',
    attributes: { 'iso3166-1-alpha-2': 'SJ', name: 'Svalbard and Jan Mayen' },
    links: { self: '/v2/countries/SJ' },
  },
  {
    type: 'countries',
    id: 'SE',
    attributes: { 'iso3166-1-alpha-2': 'SE', name: 'Sweden' },
    links: { self: '/v2/countries/SE' },
  },
  {
    type: 'countries',
    id: 'CH',
    attributes: { 'iso3166-1-alpha-2': 'CH', name: 'Switzerland' },
    links: { self: '/v2/countries/CH' },
  },
  {
    type: 'countries',
    id: 'TW',
    attributes: { 'iso3166-1-alpha-2': 'TW', name: 'Taiwan, Province of China' },
    links: { self: '/v2/countries/TW' },
  },
  {
    type: 'countries',
    id: 'TJ',
    attributes: { 'iso3166-1-alpha-2': 'TJ', name: 'Tajikistan' },
    links: { self: '/v2/countries/TJ' },
  },
  {
    type: 'countries',
    id: 'TZ',
    attributes: { 'iso3166-1-alpha-2': 'TZ', name: 'Tanzania, United Republic of' },
    links: { self: '/v2/countries/TZ' },
  },
  {
    type: 'countries',
    id: 'TH',
    attributes: { 'iso3166-1-alpha-2': 'TH', name: 'Thailand' },
    links: { self: '/v2/countries/TH' },
  },
  {
    type: 'countries',
    id: 'TL',
    attributes: { 'iso3166-1-alpha-2': 'TL', name: 'Timor-Leste' },
    links: { self: '/v2/countries/TL' },
  },
  {
    type: 'countries',
    id: 'TG',
    attributes: { 'iso3166-1-alpha-2': 'TG', name: 'Togo' },
    links: { self: '/v2/countries/TG' },
  },
  {
    type: 'countries',
    id: 'TK',
    attributes: { 'iso3166-1-alpha-2': 'TK', name: 'Tokelau' },
    links: { self: '/v2/countries/TK' },
  },
  {
    type: 'countries',
    id: 'TO',
    attributes: { 'iso3166-1-alpha-2': 'TO', name: 'Tonga' },
    links: { self: '/v2/countries/TO' },
  },
  {
    type: 'countries',
    id: 'TT',
    attributes: { 'iso3166-1-alpha-2': 'TT', name: 'Trinidad and Tobago' },
    links: { self: '/v2/countries/TT' },
  },
  {
    type: 'countries',
    id: 'TN',
    attributes: { 'iso3166-1-alpha-2': 'TN', name: 'Tunisia' },
    links: { self: '/v2/countries/TN' },
  },
  {
    type: 'countries',
    id: 'TR',
    attributes: { 'iso3166-1-alpha-2': 'TR', name: 'Turkey' },
    links: { self: '/v2/countries/TR' },
  },
  {
    type: 'countries',
    id: 'TM',
    attributes: { 'iso3166-1-alpha-2': 'TM', name: 'Turkmenistan' },
    links: { self: '/v2/countries/TM' },
  },
  {
    type: 'countries',
    id: 'TC',
    attributes: { 'iso3166-1-alpha-2': 'TC', name: 'Turks and Caicos Islands' },
    links: { self: '/v2/countries/TC' },
  },
  {
    type: 'countries',
    id: 'TV',
    attributes: { 'iso3166-1-alpha-2': 'TV', name: 'Tuvalu' },
    links: { self: '/v2/countries/TV' },
  },
  {
    type: 'countries',
    id: 'UG',
    attributes: { 'iso3166-1-alpha-2': 'UG', name: 'Uganda' },
    links: { self: '/v2/countries/UG' },
  },
  {
    type: 'countries',
    id: 'UA',
    attributes: { 'iso3166-1-alpha-2': 'UA', name: 'Ukraine' },
    links: { self: '/v2/countries/UA' },
  },
  {
    type: 'countries',
    id: 'AE',
    attributes: { 'iso3166-1-alpha-2': 'AE', name: 'United Arab Emirates' },
    links: { self: '/v2/countries/AE' },
  },
  {
    type: 'countries',
    id: 'GB',
    attributes: {
      'iso3166-1-alpha-2': 'GB',
      name: 'United Kingdom of Great Britain and Northern Ireland',
    },
    links: { self: '/v2/countries/GB' },
  },
  {
    type: 'countries',
    id: 'UM',
    attributes: { 'iso3166-1-alpha-2': 'UM', name: 'United States Minor Outlying Islands' },
    links: { self: '/v2/countries/UM' },
  },
  {
    type: 'countries',
    id: 'US',
    attributes: { 'iso3166-1-alpha-2': 'US', name: 'United States of America' },
    links: { self: '/v2/countries/US' },
  },
  {
    type: 'countries',
    id: 'UY',
    attributes: { 'iso3166-1-alpha-2': 'UY', name: 'Uruguay' },
    links: { self: '/v2/countries/UY' },
  },
  {
    type: 'countries',
    id: 'UZ',
    attributes: { 'iso3166-1-alpha-2': 'UZ', name: 'Uzbekistan' },
    links: { self: '/v2/countries/UZ' },
  },
  {
    type: 'countries',
    id: 'VU',
    attributes: { 'iso3166-1-alpha-2': 'VU', name: 'Vanuatu' },
    links: { self: '/v2/countries/VU' },
  },
  {
    type: 'countries',
    id: 'VE',
    attributes: { 'iso3166-1-alpha-2': 'VE', name: 'Venezuela (Bolivarian Republic of)' },
    links: { self: '/v2/countries/VE' },
  },
  {
    type: 'countries',
    id: 'VN',
    attributes: { 'iso3166-1-alpha-2': 'VN', name: 'Viet Nam' },
    links: { self: '/v2/countries/VN' },
  },
  {
    type: 'countries',
    id: 'VG',
    attributes: { 'iso3166-1-alpha-2': 'VG', name: 'Virgin Islands (British)' },
    links: { self: '/v2/countries/VG' },
  },
  {
    type: 'countries',
    id: 'VI',
    attributes: { 'iso3166-1-alpha-2': 'VI', name: 'Virgin Islands (U.S.)' },
    links: { self: '/v2/countries/VI' },
  },
  {
    type: 'countries',
    id: 'WF',
    attributes: { 'iso3166-1-alpha-2': 'WF', name: 'Wallis and Futuna' },
    links: { self: '/v2/countries/WF' },
  },
  {
    type: 'countries',
    id: 'EH',
    attributes: { 'iso3166-1-alpha-2': 'EH', name: 'Western Sahara' },
    links: { self: '/v2/countries/EH' },
  },
  {
    type: 'countries',
    id: 'YE',
    attributes: { 'iso3166-1-alpha-2': 'YE', name: 'Yemen' },
    links: { self: '/v2/countries/YE' },
  },
  {
    type: 'countries',
    id: 'ZM',
    attributes: { 'iso3166-1-alpha-2': 'ZM', name: 'Zambia' },
    links: { self: '/v2/countries/ZM' },
  },
  {
    type: 'countries',
    id: 'ZW',
    attributes: { 'iso3166-1-alpha-2': 'ZW', name: 'Zimbabwe' },
    links: { self: '/v2/countries/ZW' },
  },
]
// { name: 'Afghanistan', code: 'AF' },
const COUNTRIES = PT_COUNTRIES.map((country) => {
  return {
    name: country.attributes.name,
    code: country.id,
    available: true,
  }
})

const COUNTRIES_AS_OBJECT = {}
COUNTRIES.forEach((country) => {
  COUNTRIES_AS_OBJECT[country.code] = country.code
})

module.exports = {
  PT_COUNTRIES,
  COUNTRIES,
  COUNTRIES_AS_OBJECT,
}
