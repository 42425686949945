import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Space, Dropdown, Menu, Popconfirm } from "antd";
import MenuOutlined from "@ant-design/icons/lib/icons/MenuOutlined";
import { Context } from "../../store";
import { deleteUser, toggleUser } from "../../services/admin";
import EventEmitter from "../../services/event-emitter";
import events from "./events";
import { USER_ROLES_AS_OBJECT } from "eurst-shared/src/enums";

const label = "UserActions";

function UserActions({ requestedUser, filter }) {
  const [loading, setLoading] = useState(false);

  const [state] = useContext(Context);
  const { user } = state;

  const cancel = () => {
    return;
  };

  const handleDeleteUser = async (requestedUserId) => {
    setLoading(true);

    try {
      const response = await deleteUser(requestedUserId);
      if (response.status === 200) {
        toast("User is successfully deleted.", {
          type: "success",
          position: "bottom-right",
        });
      }
    } catch (error) {
      console.error(`${label}.handleDeleteUser`, error);
    } finally {
      EventEmitter.dispatch(events.deleteUser, filter);
      setLoading(false);
    }
  };

  const handleUpdateToggleDisabled = async (requestedUserId, disabled) => {
    setLoading(true);

    try {
      const response = await toggleUser(requestedUserId, disabled);
      if (response.status === 200) {
        toast("User is successfully updated.", {
          type: "success",
          position: "bottom-right",
        });
      }
    } catch (error) {
      console.error(`${label}.handleUpdateToggleDisabled`, error);
    } finally {
      EventEmitter.dispatch(events.userUpdated, filter);
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Space size="middle">
        {state.user.email !== requestedUser.email && (
          <Dropdown
            overlay={() => (
              <Menu>
                <Menu.Item>
                  <Link to={`/main/users/${requestedUser.id}`}>User details</Link>
                </Menu.Item>
                {user.role === USER_ROLES_AS_OBJECT.root && (
                  <Menu.Item
                    onClick={
                      () =>
                        /*eslint-disable  no-extra-boolean-cast*/
                        handleUpdateToggleDisabled(requestedUser.id, !!!requestedUser.disabled)
                      /*eslint-enable  no-extra-boolean-cast*/
                    }>
                    {requestedUser.disabled ? "Enable User" : "Disable User"}
                  </Menu.Item>
                )}
                {user.role === USER_ROLES_AS_OBJECT.root && (
                  <Menu.Item>
                    <Popconfirm
                      title="Are you sure to delete this user?"
                      onConfirm={() => handleDeleteUser(requestedUser.id)}
                      onCancel={cancel}
                      okText="Yes"
                      cancelText="No">
                      <a href="#" disabled={loading}>
                        Delete
                      </a>
                    </Popconfirm>
                  </Menu.Item>
                )}
              </Menu>
            )}>
            <a className="ant-dropdown-link">
              <MenuOutlined />
            </a>
          </Dropdown>
        )}
      </Space>
    </React.Fragment>
  );
}

export default UserActions;
