import React, { useContext } from "react";
import { Select } from "antd";
import { Context } from "../../store";
import { USER_ROLES } from "eurst-shared/src/enums";

function UserRoles({ requestedUser, handleUpdateUserRole }) {
  const [state] = useContext(Context);

  const { user } = state;

  return (
    <React.Fragment>
      {state.user.email !== requestedUser.email && (
        <Select
          disabled={user.disableForRole}
          defaultValue={requestedUser.role}
          style={{ width: "100%" }}
          onChange={(value) => handleUpdateUserRole(requestedUser.id, value)}
          size={"small"}>
          {Object.keys(USER_ROLES).map((roleKey) => {
            return (
              <Select.Option key={USER_ROLES[roleKey].id} value={USER_ROLES[roleKey].id}>
                {USER_ROLES[roleKey].label}
              </Select.Option>
            );
          })}
        </Select>
      )}
    </React.Fragment>
  );
}

export default UserRoles;
