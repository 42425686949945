/* eslint-disable */
import Cookies from "js-cookie";
import { getInstance } from "../utils/helpers";
import { mapAndPaginationToServerPagination } from "../utils/helpers";
import { BALANCE_TYPES_AS_OBJECT } from "eurst-shared/src/enums";

function getLogs(filter = {}) {
  const url = `/api/logtail/list`;
  const instance = getInstance();

  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  const params = {};

  Object.keys(filter).forEach((key) => {
    if (filter[key]) {
      params[key] = filter[key];
    }
  });
  return instance.get(url, { params });
}

export { getLogs };
