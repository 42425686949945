import React from "react";
import { USER_STATUSES_AS_OBJECT } from "eurst-shared/src/enums";
import { Button, Popconfirm } from "antd";

function UserRetryKycButton({ user, onSave, loading }) {
  const cancel = () => {};

  if (user.status === USER_STATUSES_AS_OBJECT.kycRequested) {
    return (
      <React.Fragment>
        <Popconfirm
          title="Are you sure to save retry wallex webhook with KYC?. User will be notified by email"
          onConfirm={onSave}
          onCancel={cancel}
          okText="Yes"
          cancelText="No">
          <Button type="primary" loading={loading}>
            Retry KYC
          </Button>
        </Popconfirm>
      </React.Fragment>
    );
  }
  return null;
}

export default UserRetryKycButton;
