/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import { getOwnerSettings, postOwnerSettings } from "../../services/admin";
import { COUNTRIES } from "eurst-shared/src/helpers/countries";
import { toast } from "react-toastify";
import { calculationOfDecimalNumbers } from "eurst-shared/src/helpers/math";
import { USER_ROLES_AS_OBJECT } from "eurst-shared/src/enums";
import { flatten, unflattenObject } from "eurst-shared/src/helpers/object";
import "./index.css";
import { Context } from "../../store";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const objectToForm = (serverValues) => {
  const result = flatten(serverValues);

  return result;
};

const formToServerObject = (formValues, delimiter = ".") => {
  return unflattenObject(formValues, delimiter);
};

function SettingsPage() {
  const [state] = useContext(Context);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [ownerSettings, setOwnerSettings] = useState({});

  const { user } = state;

  useEffect(() => {
    fetchOwnerSettings().catch(console.error);
  }, []);

  const disabledByRole = () => user.disableForRole;

  const fetchOwnerSettings = async () => {
    setLoading(true);

    const data = await getOwnerSettings();

    setOwnerSettings(data.data);
    setLoading(false);

    const formValues = objectToForm(data.data);

    form.setFieldsValue(formValues);
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const onFinish = async (values) => {
    const serverValues = formToServerObject(values);

    try {
      setLoading(true);
      const response = await postOwnerSettings(serverValues);
      if (response.status === 200) {
        toast("You have successfully updated your custom settings!", {
          type: "success",
        });
      }
    } catch (error) {
      console.error("settings page", error);
      // parsed in axios interceptor
    } finally {
      setLoading(false);
    }
  };

  const onFieldsChange = (changedField, allFields) => {
    const hasErrors = allFields.some(function (field) {
      return field.errors.length > 0;
    });
    if (hasErrors) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  const onChange = (fieldName) => {
    return (event) => {
      const { value } = event.target;

      if (
        [
          "fee",
          "mintFee",
          "redeemFee",
          "minMintAmount",
          "maxMintAmount",
          "minRedeemAmount",
          "maxRedeemAmount",
          "minWithdrawAmount",
          "maxWithdrawAmount",
        ].includes(fieldName)
      ) {
        let updateField = {};

        const newValue = value.replace(/[^\d.]+/g, "");
        const numbersAfterDot = calculationOfDecimalNumbers(newValue);

        if (value === " ") {
          updateField[fieldName] = "";
        } else {
          updateField[fieldName] = numbersAfterDot > 2 ? newValue.slice(0, -1) : newValue;
        }

        form.setFieldsValue(updateField);
      }
    };
  };

  return (
    <React.Fragment>
      <Card title="Settings" bordered={false}>
        <Form
          {...formItemLayout}
          form={form}
          name="settings"
          style={{ height: "100%", textAlign: "left" }}
          layout={"vertical"}
          onFinish={onFinish}
          onFieldsChange={(changedFields, allFields) => onFieldsChange(changedFields, allFields)}
          initialValues={{
            businessName: ownerSettings.businessName,
            supportEmail: ownerSettings.supportEmail,
            fee: ownerSettings.fee,
          }}
          scrollToFirstError>
          <Row>
            <Col span={10} offset={2}>
              <fieldset>
                <legend>Common</legend>
                <Form.Item
                  label="Business Name"
                  name="businessName"
                  rules={[
                    {
                      required: true,
                      message: "Business name is required!",
                    },
                  ]}>
                  <Input disabled={disabledByRole()} />
                </Form.Item>
                <Form.Item
                  label="Support Email"
                  name="supportEmail"
                  type="email"
                  rules={[
                    {
                      required: true,
                      message: "Support Email is required!",
                    },
                    {
                      type: "email",
                      message: "Support Email should be valid email!",
                    },
                  ]}>
                  <Input disabled={disabledByRole()} />
                </Form.Item>
              </fieldset>
              <fieldset>
                <legend>Fees in percents</legend>
                <Form.Item
                  label="Mint Fee (%)"
                  name="mintFee"
                  rules={[
                    {
                      required: true,
                      message: "Mint fee is required!",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        // minimum: 0.5,
                        // maximum: 99.9,
                        if (+value < 1) {
                          return Promise.reject(`Minimum mint fee is 1`);
                        }
                        if (+value > 99.9) {
                          return Promise.reject(`Maximum mint fee is 99.9`);
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}>
                  <Input onChange={onChange("mintFee")} disabled={disabledByRole()} />
                </Form.Item>
                <Form.Item
                  label="Redeem Fee (%)"
                  name="redeemFee"
                  rules={[
                    {
                      required: true,
                      message: "Redeem fee is required!",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        // minimum: 0.5,
                        // maximum: 99.9,
                        if (+value < 1) {
                          return Promise.reject(`Minimum redeem fee is 1`);
                        }
                        if (+value > 99.9) {
                          return Promise.reject(`Maximum redeem fee is 99.9`);
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}>
                  <Input onChange={onChange("redeemFee")} disabled={disabledByRole()} />
                </Form.Item>
              </fieldset>
              <fieldset>
                <legend>Minter balance email notifications</legend>
                <Form.Item
                  label="Interval to check minter balance(hours)"
                  name="minterBalanceCheckInterval"
                  rules={[
                    {
                      required: true,
                      message: "required!",
                    },
                    {
                      type: "number",
                      message: "Should be number",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || +value < 1) {
                          return Promise.reject("Should be more than 0!");
                        }
                        if (+value > 23) {
                          return Promise.reject("Should be less than 24!");
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}>
                  <Select style={{ textAlign: "initial" }} disabled={disabledByRole()}>
                    {Array.from(Array(23).keys()).map((item, index) => {
                      const val = index + 1;

                      return (
                        <Option value={val} key={`minterBalanceCheckInterval-${val}`}>
                          {val}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Min minter`s balance for email notification(ethers, precision 18)"
                  name="minterBalanceMinValue"
                  rules={[
                    {
                      required: true,
                      message: "required!",
                    },
                    {
                      pattern: /^[+-]?\d+(\.\d+)?$/,
                      message: "Should be decimal number.Example: 0.0002",
                    },
                    {
                      max: 18,
                      message: "Precision - 18 digits only",
                    },
                  ]}>
                  <Input
                    onChange={onChange("minterBalanceMinValue")}
                    disabled={disabledByRole()}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </fieldset>
            </Col>
            <Col span={10} offset={2}>
              <fieldset>
                <legend>Mint settings</legend>
                <Form.Item
                  label="Min mint amount (EURST)"
                  name="minMintAmount"
                  rules={[
                    {
                      required: true,
                      message: "Min mint amount is required!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || +value < 1) {
                          return Promise.reject("Min mint amount should be more than 1!");
                        }

                        return Promise.resolve();
                      },
                    }),
                  ]}>
                  <Input onChange={onChange("minMintAmount")} disabled={disabledByRole()} />
                </Form.Item>
                <Form.Item
                  label="Max mint amount (EURST)"
                  name="maxMintAmount"
                  rules={[
                    {
                      required: true,
                      message: "Max mint amount is required!",
                    },
                  ]}>
                  <Input onChange={onChange("maxMintAmount")} disabled={disabledByRole()} />
                </Form.Item>
              </fieldset>
              <fieldset>
                <legend>Redeem settings</legend>
                <Form.Item
                  label="Min redeem amount (EURST)"
                  name="minRedeemAmount"
                  rules={[
                    {
                      required: true,
                      message: "Min redeem amount is required!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || +value < 1) {
                          return Promise.reject("Min redeem amount should be more than 1!");
                        }

                        return Promise.resolve();
                      },
                    }),
                  ]}>
                  <Input onChange={onChange("minRedeemAmount")} disabled={disabledByRole()} />
                </Form.Item>
                <Form.Item
                  label="Max redeem amount (EURST)"
                  name="maxRedeemAmount"
                  rules={[
                    {
                      required: true,
                      message: "Max redeem amount is required!",
                    },
                  ]}>
                  <Input onChange={onChange("maxRedeemAmount")} disabled={disabledByRole()} />
                </Form.Item>
              </fieldset>
              <fieldset>
                <legend>Withdraw settings</legend>
                <Form.Item
                  label="Min withdraw amount (USD)"
                  name="minWithdrawAmount"
                  rules={[
                    {
                      required: true,
                      message: "Min Withdraw amount is required!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || +value < 1) {
                          return Promise.reject("Min mint amount should be more than 1!");
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}>
                  <Input disabled={disabledByRole()} onChange={onChange("minWithdrawAmount")} />
                </Form.Item>
                <Form.Item
                  label="Max Withdraw amount (USD)"
                  name="maxWithdrawAmount"
                  rules={[
                    {
                      required: true,
                      message: "Max Withdraw amount is required!",
                    },
                  ]}>
                  <Input disabled={disabledByRole()} onChange={onChange("maxWithdrawAmount")} />
                </Form.Item>
              </fieldset>
            </Col>
          </Row>
          <Row>
            <Col span={10} offset={2}>
              <fieldset>
                <legend>Bank Information</legend>
                <Form.Item
                  label="Bank Account Name"
                  name="bankInfo.bankAccountName"
                  style={{ marginBottom: 8 }}
                  rules={[
                    {
                      required: true,
                      message: "Bank Account Name is required!",
                    },
                  ]}>
                  <Input disabled={disabledByRole()} />
                </Form.Item>

                <Form.Item
                  label="Bank Account Number"
                  name="bankInfo.bankAccountNumber"
                  style={{ marginBottom: 8 }}
                  rules={[
                    {
                      required: true,
                      message: "Bank Account Number is required!",
                    },
                  ]}>
                  <Input disabled={disabledByRole()} />
                </Form.Item>

                <Form.Item
                  label="Bank Routing Number"
                  name="bankInfo.routingNumber"
                  style={{ marginBottom: 8 }}
                  rules={[
                    {
                      required: true,
                      message: "Bank Routing Number is required!",
                    },
                  ]}>
                  <Input disabled={disabledByRole()} />
                </Form.Item>

                <Form.Item
                  label="Bank Contact phone"
                  name="bankInfo.phone"
                  style={{ marginBottom: 8 }}
                  rules={[
                    {
                      required: true,
                      message: "Bank Contact phone is required!",
                    },
                  ]}>
                  <Input disabled={disabledByRole()} />
                </Form.Item>

                <Form.Item
                  label="Credit"
                  name="bankInfo.credit"
                  style={{ marginBottom: 8 }}
                  rules={[
                    {
                      required: true,
                      message: "Credit is required!",
                    },
                  ]}>
                  <Input disabled={disabledByRole()} />
                </Form.Item>

                <Form.Item
                  label="IBAN"
                  name="bankInfo.iban"
                  style={{ marginBottom: 8 }}
                  rules={[
                    {
                      required: true,
                      message: "IBAN is required!",
                    },
                  ]}>
                  <Input disabled={disabledByRole()} />
                </Form.Item>

                <Form.Item
                  label="SWIFT"
                  name="bankInfo.swift"
                  style={{ marginBottom: 8 }}
                  rules={[
                    {
                      required: true,
                      message: "SWIFT is required!",
                    },
                  ]}>
                  <Input disabled={disabledByRole()} />
                </Form.Item>
              </fieldset>
            </Col>
            <Col span={10} offset={2}>
              <fieldset>
                <legend>Bank address</legend>
                <Form.Item
                  label="City"
                  name="bankInfo.address.city"
                  style={{ marginBottom: 8 }}
                  rules={[
                    {
                      required: true,
                      message: "City is required!",
                    },
                  ]}>
                  <Input disabled={disabledByRole()} />
                </Form.Item>

                <Form.Item
                  label="Country"
                  name="bankInfo.address.country"
                  style={{ marginBottom: 8 }}
                  rules={[
                    {
                      required: true,
                      message: "Country is required!",
                    },
                  ]}>
                  <Select
                    disabled={disabledByRole()}
                    style={{ width: "100%", textAlign: "initial" }}
                    placeholder="Select a country">
                    {COUNTRIES.map((item, index) => (
                      <Option value={item.code} key={index}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Postal Code"
                  name="bankInfo.address.postalCode"
                  style={{ marginBottom: 8 }}
                  rules={[
                    {
                      required: true,
                      message: "Postal Code is required!",
                    },
                  ]}>
                  <Input disabled={disabledByRole()} />
                </Form.Item>

                <Form.Item
                  label="Region"
                  name="bankInfo.address.region"
                  style={{ marginBottom: 8 }}
                  rules={[
                    {
                      required: true,
                      message: "Region is required!",
                    },
                  ]}>
                  <Input disabled={disabledByRole()} />
                </Form.Item>

                <Form.Item
                  label="Street"
                  name="bankInfo.address.street"
                  style={{ marginBottom: 8 }}
                  rules={[
                    {
                      required: true,
                      message: "Street is required!",
                    },
                  ]}>
                  <Input disabled={disabledByRole()} />
                </Form.Item>
              </fieldset>
            </Col>
          </Row>
          <Row gutter={[24, 24]} className="row-right-aligned">
            <Col offset={20}>
              <Form.Item {...tailLayout}>
                <Button
                  disabled={disabled || disabledByRole()}
                  loading={loading}
                  type="primary"
                  htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </React.Fragment>
  );
}

export default SettingsPage;
