import React, { useState } from "react";
import { Drawer, Button, Form, Input, Tag, Select, Badge } from "antd";
import { PACKAGE_NAMES, LOG_LEVELS } from "eurst-shared/src/enums";
import { getFilledInObject } from "../../utils/helpers";

const { Option } = Select;

function LogsFilter({ onSubmit, onReset, loading }) {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [count, setCount] = useState(0);

  const filtersFilled = () => {
    const values = form.getFieldsValue();

    const count = getFilledInObject(values);

    setCount(count);
  };

  const onChange = (name, value) => {
    form.setFieldsValue({ [name]: value });
  };

  const onFiltersReady = (values) => {
    onSubmit({
      ...values,
    });

    filtersFilled();

    onClose();
  };

  const onClearFilter = () => {
    form.resetFields();

    onReset({});

    filtersFilled();

    onClose();
  };

  const onClose = () => {
    setVisible(false);
  };

  if (!visible) {
    return (
      <React.Fragment>
        <Badge count={count}>
          <Button type="primary" onClick={() => setVisible(true)}>
            Filter
          </Button>
        </Badge>
        <Button disabled={loading} onClick={onClearFilter}>
          Reset
        </Button>
      </React.Fragment>
    );
  }
  return (
    <Drawer visible={visible} title="Filter logs" width={400} onClose={onClose}>
      <Form
        form={form}
        name="filter"
        style={{ height: "100%", textAlign: "left" }}
        layout={"vertical"}
        onFinish={onFiltersReady}
        initialValues={{}}
        scrollToFirstError>
        <Form.Item label="Log groups" name="name">
          <Select onChange={(value) => onChange("name", value)}>
            {PACKAGE_NAMES.map((type) => {
              return (
                <Option key={type.id} label={type.id} value={type.id}>
                  {type.id}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item label="Log levels" name="level">
          <Select onChange={(value) => onChange("level", value)}>
            {LOG_LEVELS.map((level) => {
              return (
                <Option key={level.id} label={level.label} value={level.id}>
                  <Tag color={level.color}>{level.label}</Tag>
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Message contents" name="message">
          <Input />
        </Form.Item>

        <Form.Item label="Status code" name="statusCode">
          <Input />
        </Form.Item>

        <Button type="primary" loading={loading} htmlType="submit">
          Search
        </Button>
        <Button loading={loading} onClick={onClearFilter}>
          Reset
        </Button>
      </Form>
    </Drawer>
  );
}

export default LogsFilter;
