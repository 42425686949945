/* eslint-disable */
import React, { useState } from "react";
import { Drawer, Button, DatePicker, Form, Input, Select, Badge, Switch } from "antd";
import { FORMAT_FOR_DATE_WITH_TIME } from "../../constants/constants";
import { USER_ROLES, USER_TYPES, USER_STATUSES } from "eurst-shared/src/enums";
import { getFilledInObject } from "../../utils/helpers";

const { RangePicker } = DatePicker;
const { Option } = Select;

function UserFilter({ onSubmit, onReset, loading }) {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [count, setCount] = useState(0);

  const filtersFilled = () => {
    const values = form.getFieldsValue();

    const count = getFilledInObject(values);

    setCount(count);
  };

  const onChange = (name, value) => {
    form.setFieldsValue({ [name]: value });

    if (name === "editableUsers") {
      const formValues = form.getFieldsValue();

      if (value) {
        formValues.status.push(USER_STATUSES.kycRequested.id);
      } else {
        formValues.status = formValues.status.filter((s) => s !== USER_STATUSES.kycRequested.id);
      }
      form.setFieldsValue({ status: formValues.status });
    }
  };

  const onFiltersReady = (values) => {
    let created = {};
    if (values.createdAt && values.createdAt.length > 0) {
      created.createdAtFrom = values.createdAt[0].toISOString();
      created.createdAtTo = values.createdAt[1].toISOString();
    }
    onSubmit({
      ...values,
      ...created,
    });

    filtersFilled();

    onClose();
  };

  const onClearFilter = () => {
    form.resetFields();

    onReset({});

    filtersFilled();

    onClose();
  };

  const onClose = () => {
    setVisible(false);
  };

  if (!visible) {
    return (
      <React.Fragment>
        <Badge count={count}>
          <Button disabled={loading} type="primary" onClick={() => setVisible(true)}>
            Filter
          </Button>
        </Badge>
        <Button disabled={loading} onClick={onClearFilter}>
          Reset
        </Button>
      </React.Fragment>
    );
  }
  return (
    <Drawer visible={visible} title="Search users" width={400} onClose={onClose}>
      <Form
        form={form}
        name="filter"
        style={{ height: "100%", textAlign: "left" }}
        layout={"vertical"}
        onFinish={onFiltersReady}
        initialValues={{}}
        scrollToFirstError>
        <Form.Item name="createdAt" label="Date">
          <RangePicker
            inputReadOnly
            format={FORMAT_FOR_DATE_WITH_TIME}
            style={{ width: 250, marginBottom: 8 }}
          />
        </Form.Item>
        <Form.Item label="User ID" name="id">
          <Input />
        </Form.Item>
        <Form.Item label="User email" name="email">
          <Input />
        </Form.Item>
        <Form.Item label="Wallet" name="ethAddress">
          <Input />
        </Form.Item>
        <Form.Item label="PrimeTrust account id" name="ptAccountId">
          <Input />
        </Form.Item>
        <Form.Item label="PrimeTrust contact id" name="ptContactId">
          <Input />
        </Form.Item>
        <Form.Item label="PrimeTrust reference" name="ptReference">
          <Input />
        </Form.Item>

        <Form.Item label="Type" name="type">
          <Select mode="multiple" allowClear onChange={(value) => onChange("type", value)}>
            {USER_TYPES.map((type) => {
              return (
                <Option key={type.id} label={type.label} value={type.id}>
                  {type.label}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item label="Status" name="status">
          <Select mode="multiple" allowClear onChange={(value) => onChange("status", value)}>
            {Object.keys(USER_STATUSES).map((key) => {
              return (
                <Option
                  key={USER_STATUSES[key].id}
                  label={USER_STATUSES[key].label}
                  value={USER_STATUSES[key].id}>
                  {USER_STATUSES[key].label}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item label="Role" name="role">
          <Select mode="multiple" allowClear onChange={(value) => onChange("role", value)}>
            {Object.keys(USER_ROLES).map((key) => {
              return (
                <Option
                  key={USER_ROLES[key].id}
                  label={USER_ROLES[key].label}
                  value={USER_ROLES[key].id}>
                  {USER_ROLES[key].label}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Button type="primary" loading={loading} htmlType="submit">
          Search
        </Button>
        <Button loading={loading} onClick={onClearFilter}>
          Reset
        </Button>
      </Form>
    </Drawer>
  );
}

export default UserFilter;
