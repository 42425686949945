/* eslint-disable */

import React, { useState, useEffect, useContext } from "react";
import { Card, Row, Col, Avatar, Form, Divider, Input, Select, Typography, Button } from "antd";
import { Context } from "../../store";
import { useParams } from "react-router-dom";
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import EditOutlined from "@ant-design/icons/lib/icons/EditOutlined";
import { STATUSES_KYC_COLOR } from "eurst-frontend/src/constants/constants";
import { flatten, unflattenObject } from "eurst-shared/src/helpers/object";
import PhoneInput from "react-phone-input-2";
import { etherscanUrl } from "eurst-shared/src/helpers/urls";
import { updateUser, fetchDoc, getUserById, retryKyc } from "../../services/users";
import { updateKycDocumentText } from "../../services/kyc-document-checks";
import moment from "moment";
import { USER_STATUSES_AS_OBJECT } from "eurst-shared/src/enums";
import { shortener } from "eurst-shared/src/helpers/string";
import { CopyableText, CopyableLink } from "../../components/Copyable";
import {
  UserDateOfBirth,
  UserGender,
  UserName,
  UserStatus,
  UserAlerts,
  UserEditableAlerts,
  UserAddress,
  UserCitizenship,
  UserType,
  UserContactCheckFlags,
  UserRetryKycButton,
} from "../../components/UserEditor";
import UserEditorLabel from "../../components/UserEditor/editor-label";

import "./index.css";
import "react-phone-input-2/lib/style.css";

const { Option } = Select;
const { Text, Link } = Typography;
const network = process.env.REACT_APP_NETWORK;

const objectToForm = (serverValues) => {
  const result = flatten(serverValues);
  return result;
};

const formToServerObject = (formValues, delimiter = ".") => {
  return unflattenObject(formValues, delimiter);
};

function ReadOnlyCopyableFormItemText({ user, keyName, label, hideCopy }) {
  return (
    <Form.Item label={label}>
      &nbsp;
      {user[keyName] ? (
        hideCopy ? (
          user[keyName]
        ) : (
          <CopyableText value={user[keyName]} label={user[keyName]} />
        )
      ) : (
        "n/a"
      )}
    </Form.Item>
  );
}

function ReadOnlyCopyableFormItemEthLink({ user, keyName, label, shortenerEnabled }) {
  if (!user[keyName]) {
    return <Form.Item label={label}>&nbsp;n/a</Form.Item>;
  }
  const newLabel = shortenerEnabled ? shortener(user[keyName]) : user[keyName];

  return (
    <Form.Item label={label}>
      &nbsp;
      <CopyableLink
        label={newLabel}
        value={user[keyName]}
        url={etherscanUrl(network, "address", user[keyName])}
      />
    </Form.Item>
  );
}

function DocUrl({ doc, requestedUser }) {
  const [urlLoading, setUrlLoading] = useState(false);

  const onClickByDoc = async (item) => {
    setUrlLoading(true);
    try {
      const result = await fetchDoc(doc.ptDocumentId, requestedUser.id);

      if (!result.data.url) {
        return;
      }
      window.open(result.data.url, "_blank").focus();
    } finally {
      setUrlLoading(false);
    }
  };

  return (
    <div key={`doc${doc.id}`} style={{ textAlign: "left" }}>
      <Button
        loading={urlLoading}
        type="link"
        key={`btn${doc.id}`}
        onClick={() => onClickByDoc(doc)}>
        {doc.result.data.attributes["label"]}
      </Button>
    </div>
  );
}

function UserDetailsPage({}) {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [requestedUser, setRequestedUser] = useState({});

  const [state] = useContext(Context);

  const { user } = state;

  useEffect(() => {
    (async () => {
      const requestedUser = await fetchData();
      const values = objectToForm(requestedUser);

      values["personalInfo.dateOfBirth"] = moment(values["personalInfo.dateOfBirth"]);

      if (requestedUser.scProfile) {
        requestedUser["scProfile.redeemAddress"] = requestedUser.scProfile.redeemAddress;
        requestedUser["scProfile.KYC"] = requestedUser.scProfile.KYC ? "true" : "false";
        requestedUser["scProfile.canBurn"] = requestedUser.scProfile.canBurn ? "true" : "false";
        requestedUser["scProfile.blocked"] = requestedUser.scProfile.blocked ? "true" : "false";
      }
      if (requestedUser.ptAccountInfo) {
        requestedUser["ptAccountInfo.status"] = requestedUser.ptAccountInfo.data.attributes.status;
      }
      setRequestedUser(requestedUser);

      form.setFieldsValue(values);

      const isEdit = [USER_STATUSES_AS_OBJECT.kycRequested].includes(requestedUser.status);
      setEditMode(isEdit);
    })();
  }, []);

  const onFinish = async (values) => {
    if (!editMode) {
      return;
    }

    setSaving(true);
    try {
      await form.validateFields();

      const serverValues = formToServerObject(values);

      serverValues.related = serverValues.personalInfo;

      const fields = ["firstName", "lastName", "userName"];
      fields.forEach((key) => {
        serverValues.related[key] = serverValues[key];
      });

      serverValues.related.address = requestedUser.personalInfo.address;

      delete serverValues.personalInfo;

      if (serverValues.related.dateOfBirth && serverValues.related.dateOfBirth.format) {
        serverValues.related.dateOfBirth = serverValues.related.dateOfBirth.format("YYYY-MM-DD");
      }

      await updateUser(requestedUser, serverValues);
    } catch (error) {
      console.error("UserEditor", error);
    } finally {
      setSaving(false);
    }
  };

  const onRetryKyc = async () => {
    setLoading(true);

    try {
      const response = await retryKyc(requestedUser);
      return response.data;
    } catch (err) {
      console.error("ERRR", err);
    } finally {
      setLoading(false);
    }
  };

  const getTitle = () => {
    return (
      <div>
        {editMode ? <EditOutlined /> : ""}
        &nbsp; <UserEditorLabel user={requestedUser} />
      </div>
    );
  };

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const fetchData = async () => {
    setLoading(true);

    try {
      const response = await getUserById(id);
      return response.data;
    } catch (err) {
      console.error("ERRR", err);
    } finally {
      setLoading(false);
    }
  };

  const onEditAlertKycDocumentChecks = async (row, value) => {
    await updateKycDocumentText(row.id, { description: value });

    const requestedUser = await fetchData();
    const values = objectToForm(requestedUser);

    values["personalInfo.dateOfBirth"] = moment(values["personalInfo.dateOfBirth"]);

    setRequestedUser(requestedUser);

    form.setFieldsValue(values);

    const isEdit = [USER_STATUSES_AS_OBJECT.kycRequested].includes(requestedUser.status);
    setEditMode(isEdit);
  };

  return (
    <Card loading={loading} title={getTitle()} bordered={false}>
      <Col span={24}>
        <Row justify={"center"}>
          <Avatar
            style={{
              cursor: "pointer",
              border: `4px solid ${STATUSES_KYC_COLOR[status]}`,
            }}
            size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
            icon={<UserOutlined />}
          />
        </Row>
        <Row justify={"center"}>
          <div label={"Type"}>
            Type:
            <UserType user={requestedUser} />
          </div>
        </Row>
        <Divider />
        <Row justify={"center"}>
          <Form
            form={form}
            {...layout}
            style={{ height: "100%", textAlign: "left" }}
            name="basic"
            onFinish={onFinish}
            initialValues={{}}>
            <Form.Item label={"Status"}>
              <UserStatus user={requestedUser} />
              <UserRetryKycButton user={requestedUser} onSave={onRetryKyc} />
            </Form.Item>

            <ReadOnlyCopyableFormItemText keyName="email" user={requestedUser} label="Email" />

            <UserContactCheckFlags contactProfile={requestedUser.ptContactProfile} />

            <UserAlerts
              data={requestedUser.kycRequiredActions}
              header={"KYC required actions"}
              fullText={true}
            />

            <UserEditableAlerts
              disableForRole={user.disableForRole}
              data={requestedUser.kycDocumentCheckResults}
              header={"KYC document check reasons"}
              showStatus={true}
              onEdit={onEditAlertKycDocumentChecks}
            />

            <UserName user={requestedUser} disabled={!editMode || user.disableForRole} />

            <UserDateOfBirth user={requestedUser} disabled={!editMode || user.disableForRole} />

            <UserGender user={requestedUser} disabled={!editMode || user.disableForRole} />

            <Form.Item
              name="personalInfo.phoneNumber"
              label="Phone Number"
              rules={[
                {
                  required: true,
                  message: "Please input your phone number!",
                },
                {
                  min: 10,
                  message: "The Phone Number should be more than 7 symbols!",
                  whitespace: true,
                },
              ]}>
              <PhoneInput
                disabled={!editMode || user.disableForRole}
                country={requestedUser.personalInfo?.countryOfResidence.toLocaleLowerCase()}
                style={{ width: "100%" }}
                value={requestedUser.personalInfo?.phoneNumber}
              />
            </Form.Item>

            <UserAddress
              form={form}
              user={requestedUser}
              disabled={!editMode || user.disableForRole}
            />

            <UserCitizenship user={requestedUser} disabled={!editMode || user.disableForRole} />

            <ReadOnlyCopyableFormItemEthLink
              user={requestedUser}
              label="Wallet"
              keyName="ethAddress"
              shortenerEnabled
            />

            <Form.Item
              name="personalInfo.taxIdNumber"
              label="Tax id number"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "TaxID number is required!",
                },
              ]}>
              <Input disabled={!editMode || user.disableForRole} />
            </Form.Item>

            <Divider />
            <ReadOnlyCopyableFormItemText
              keyName="scProfile.redeemAddress"
              user={requestedUser}
              label="User Registry redeem address"
            />
            <ReadOnlyCopyableFormItemText
              keyName="scProfile.KYC"
              user={requestedUser}
              hideCopy
              label="User Registry KYC flag"
            />
            <ReadOnlyCopyableFormItemText
              keyName="scProfile.canBurn"
              hideCopy
              user={requestedUser}
              label="User Registry canBurn flag"
            />
            <ReadOnlyCopyableFormItemText
              keyName="scProfile.blocked"
              hideCopy
              user={requestedUser}
              label="User Registry blocked flag"
            />

            <Divider />
            <ReadOnlyCopyableFormItemText
              keyName="ptAccountInfo.status"
              hideCopy
              user={requestedUser}
              label="PrimeTrust account status"
            />
            <ReadOnlyCopyableFormItemText
              keyName="ptAccountId"
              user={requestedUser}
              label="PrimeTrust account ID"
            />
            <ReadOnlyCopyableFormItemText
              keyName="ptContactId"
              user={requestedUser}
              label="PrimeTrust contact ID"
            />
            <ReadOnlyCopyableFormItemText
              keyName="ptReference"
              user={requestedUser}
              label="PrimeTrust reference"
            />
            <Form.Item label="Uploaded documents">
              {requestedUser.personalInfo?.uploadedDocs?.length > 0
                ? requestedUser.personalInfo.uploadedDocs.map((doc) => {
                    return <DocUrl doc={doc} requestedUser={requestedUser} />;
                  })
                : "n/a"}
            </Form.Item>
            <Divider />
            <Form.Item>
              <Button
                disabled={!editMode || user.disableForRole}
                loading={saving}
                type="primary"
                htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </Row>
      </Col>
    </Card>
  );
}

export default UserDetailsPage;
