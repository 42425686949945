import React, { useEffect, useState, useMemo } from "react";
import Layout from "antd/lib/layout";
import { getLogs } from "../../services/logtail";
import LogtailTable from "./logs-table";

const { Content } = Layout;

const SystemMonitoringPage = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const initialFilter = useMemo(() => {
    return {};
  }, []);
  const [filter, setFilter] = useState(initialFilter);

  const [pagination, setPagination] = useState({});

  const fetchData = async (filters) => {
    setLoading(true);
    try {
      const response = await getLogs(filters);
      setData(response.data);
      setPagination(response.pagination);
    } catch (err) {
      console.error("ERRR", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(initialFilter);
  }, [initialFilter]);

  const handleTableChange = (filter) => {
    setFilter(filter);
    return fetchData(filter);
  };

  return (
    <Content style={{ width: "100%" }}>
      <LogtailTable
        data={data}
        pagination={pagination}
        loading={loading}
        filter={filter}
        handleTableChange={handleTableChange}
      />
    </Content>
  );
};
export default SystemMonitoringPage;
