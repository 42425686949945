/* eslint-disable */
import Cookies from "js-cookie";
import { getInstance, mapAndPaginationToServerPagination } from "../utils/helpers";
import { USER_TYPES_AS_OBJECT } from "eurst-shared/src/enums";

function getUser() {
  const url = "/api/users/me";
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return instance.get(url);
}

function fetchDoc(ptDpcumentId, userId) {
  const url = `/api/admin/users/document-url?ptDocumentId=${ptDpcumentId}&userId=${userId}`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return instance.get(url);
}

function getUserById(id) {
  const url = `/api/admin/users/${id}`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return instance.get(url);
}

function updateUser(user, payload) {
  const url =
    user.type === USER_TYPES_AS_OBJECT.natural_person
      ? `/api/admin/users/${user.id}`
      : `/api/admin/company/${user.id}`;

  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return instance.put(url, payload);
}

function retryKyc(user) {
  const url = `/api/webhooks/retry-kyc`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return instance.post(url, { id: user.ptAccountId });
}

function updateUserRole(id, payload) {
  const url = `/api/admin/users/${id}/change-role`;
  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  return instance.put(url, payload);
}

function getUsersFilterable(filter, pagination) {
  let url = `/api/admin/users/list`;

  const pageParams = mapAndPaginationToServerPagination(pagination);

  const instance = getInstance();
  const token = Cookies.get("token");
  instance.defaults.headers.Authorization = `Bearer ${token}`;

  const params = {};

  Object.keys(filter).forEach((key) => {
    if (filter[key]) {
      params[key] = filter[key];
    }
  });

  const result = getInstance().get(url, { params: { ...params, ...pageParams } });

  return result;
}

export { getUser, updateUser, getUserById, fetchDoc, updateUserRole, getUsersFilterable, retryKyc };
