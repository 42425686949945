/* eslint-disable */
import { getInstance } from "../utils/helpers";
import { USER_ROLES_AS_OBJECT } from "eurst-shared/src/enums";
import Cookies from "js-cookie";

export const getAuthCookies = () => {
  const token = Cookies.get("token");
  const status = Cookies.get("status");

  if (!token) {
    return;
  }
  return {
    token,
    status,
  };
};

export const setAuthCookies = (token, status, channelToken, expiresIn) => {
  const number = Date.now() + parseInt(expiresIn) * 1000;
  const date = new Date(number);

  Cookies.set("token", token, { expires: date });
  Cookies.set("status", status);
  Cookies.set("channelToken", channelToken);
};

function registration({ userName, email, password, countryCode }) {
  const payload = {
    userName,
    email,
    password,
    countryCode,
  };
  const url = "/api/auth/register";
  return getInstance().post(url, payload);
}

function login({ email, password }) {
  const payload = {
    email,
    password,
  };
  const url = "/api/auth/protected";
  return new Promise(async (resolve, reject) => {
    try {
      const response = await getInstance().post(url, payload);

      if (![USER_ROLES_AS_OBJECT.root, USER_ROLES_AS_OBJECT.manager].includes(response.data.role)) {
        reject({
          data: {
            message: "User is not admin approved to access this app.",
            code: "no-permissions",
          },
        });
      } else if (response.data.disabled) {
        reject({
          data: {
            message: "User is not approved to access this app.",
            code: "user-disabled",
          },
        });
      } else {
        const number = Date.now() + parseInt(response.data.token.expiresIn) * 1000;
        const date = new Date(number);

        setAuthCookies(
          response.data.token.token,
          response.data.status,
          response.data.channelToken,
          response.data.token.expiresIn
        );

        resolve(response);
      }
    } catch (e) {
      console.log(e?.data?.message);
    }
  });
}

function logout(socket) {
  Cookies.remove("token");
  Cookies.remove("status");
  if (socket) {
    Cookies.remove("channelToken");
    //socket.emit("logout");
  }
}

function verification(token) {
  const url = "/api/auth/register-confirm";
  const payload = {
    token,
  };
  return getInstance().put(url, payload);
}

export default {
  login,
  registration,
  verification,
  logout,
  getAuthCookies,
  setAuthCookies,
};
