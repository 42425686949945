/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import Table from "antd/lib/table";
import Tag from "antd/lib/tag";
import Row from "antd/lib/grid/row";
import Col from "antd/lib/grid/col";
import List from "antd/lib/list";
import { LOG_LEVELS } from "eurst-shared/src/enums";
import { FORMAT_FOR_DATE_WITH_TIME, LOGTAIL_URL } from "../../constants/constants";
import moment from "moment";
import ReloadButton from "../../components/ReloadButton";
import { CopyableLink } from "../../components/Copyable";
import LogsFilter from "./logs-filter";
import PagerPrevNext from "../../components/PagerPrevNext";

const getLogtailUrl = (queryStringAsJson, record) => {
  const attributes = ["name", "level", "reqId", "message", "statusCode"];
  const query = {};

  attributes.forEach((attr) => {
    if (queryStringAsJson[attr]) {
      query[attr] = queryStringAsJson[attr];
    }
  });
  if (Object.keys(query).length > 0) {
    const arr = [];
    Object.keys(query).forEach((q) => {
      arr.push(`${q}:'${query[q]}'`);
    });
    const str = arr.join(", ");

    query.q = str;
  }
  attributes.forEach((attr) => {
    delete query[attr];
  });

  const result = new URL(LOGTAIL_URL);

  Object.keys(query).forEach((option) => {
    result.searchParams.append(option, query[option]);
  });
  result.searchParams.append("s", record._source_id);
  result.searchParams.append("a", `${record._dt}.${record._insert_index}`);

  const searchParams = result.toString();

  return searchParams;
};

const LogtailTable = ({ data, loading, filter, handleTableChange, pagination }) => {
  const columns = [
    {
      align: "center",
      dataIndex: "dt",
      key: `dt`,
      ellipsis: true,
      title: "Timestamp",
      width: "180px",
      render: (value) => {
        return moment(value).format(FORMAT_FOR_DATE_WITH_TIME);
      },
    },
    {
      align: "center",
      dataIndex: "level",
      key: `level`,
      ellipsis: true,
      title: "Level",
      width: "150px",
      // eslint-disable-next-line react/display-name
      render: (value) => {
        const level = LOG_LEVELS.find((l) => l.id === value);
        if (!level) {
          return value;
        }
        return (
          <>
            <Tag color={level.color}>{value}</Tag>
          </>
        );
      },
    },
    {
      align: "left",
      dataIndex: "message",
      key: `message`,
      ellipsis: true,
      responsive: ["xxl", "xl", "lg", "sm"],
      title: "Message",
      render: (value, record) => {
        const dateRegExp = new RegExp(
          /^[\d]{4}-[\d]{2}-[\d]{2}\s[\d]{2}:[\d]{2}\s.[\d]{2}:[\d]{2}:\s/
        );
        const text = record.message.replace(dateRegExp, "");
        return text;
      },
    },
    {
      align: "center",
      dataIndex: "url",
      key: `url`,
      title: "Logtail",
      width: "120px",
      // eslint-disable-next-line react/display-name
      render: (value, record) => {
        const url = getLogtailUrl(filter, record);

        return (
          <React.Fragment>
            <CopyableLink label="To logtail" value={value} url={url} />
          </React.Fragment>
        );
      },
    },
  ];

  return (
    <>
      <Row key="logs-filter" style={{ float: "right" }}>
        <LogsFilter onSubmit={handleTableChange} onReset={handleTableChange} loading={loading} />
        <ReloadButton disabled={loading} onClick={() => handleTableChange(filter)} />
      </Row>

      <Table
        className={"title-row-hidden"}
        columns={columns}
        rowKey={(record) => `${record._dt}.${record._insert_index}`}
        dataSource={data}
        pagination={false}
        scroll={{
          y: 540,
        }}
        pagination={{
          position: ["topRight", "bottomRight"],
        }}
        loading={loading}
      />

      {/*<List*/}
      {/*loading={loading}*/}
      {/*itemLayout="horizontal"*/}
      {/*dataSource={data}*/}
      {/*renderItem={item => {*/}
      {/*return <List.Item>*/}
      {/*<Row>*/}
      {/*<Col span={6}>*/}
      {/*{moment(item.dt).format(FORMAT_FOR_DATE_WITH_TIME)}*/}
      {/*</Col>*/}
      {/*<Col span={2}>*/}
      {/*{item.level}*/}
      {/*</Col>*/}
      {/*<Col span={16}>*/}
      {/*{item.message}*/}
      {/*</Col>*/}
      {/*</Row>*/}

      {/*</List.Item>*/}
      {/*}}*/}
      {/*/>*/}
    </>
  );
};

export default LogtailTable;

LogtailTable.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object),
};
